import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import assessedImage from "../images/wake-county-assessed.png"
import index from "./index.css"

export default function Home() {
	return (
		<Layout>
			<Helmet>
                <title>Yagmin Properties LLC</title>
            </Helmet>
			<p>Yagmin Properties LLC is a venture to develop and lease residential and business properties.</p>
			<h2>Partners</h2>
            <p>We are always keeping an eye out for potential partners, wholesalers, and possibly syndication deals.</p>
            <p>E-mail us at <a href="mailto:hello@yagminproperties.com">hello@yagminproperties.com</a> to discuss possible deals.</p>
            <h3>Target Markets in North Carolina</h3>
            <ul>
                <li>
                    <h3>Wake County</h3>
                    <img src={ assessedImage } alt="Wake County value per acre" className="figure" />
                </li>
                <li><h3>Randolph County</h3></li>
                <li><h3>Guilford County</h3></li>
                <li><h3>Orange County</h3></li>
                <li><h3>Chatham County</h3></li>
                <li><h3>Durham County</h3></li>
            </ul>
		</Layout>
	)
}
